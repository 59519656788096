@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;

    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;

    --popover: theme("colors.zinc.100");
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: theme("colors.stone.100");
    --card-foreground: 20 14.3% 4.1%;

    --border: theme("colors.zinc.200");

    --input: theme("colors.zinc.100");
    --input-disabled: theme("colors.zinc.200");

    --primary: 23 100% 45%;
    --primary-foreground: 60 9.1% 97.8%;

    --secondary: 60 4.8% 91.9%;
    --secondary-foreground: 24 9.8% 10%;

    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;

    --ring: theme("colors.zinc.200");

    --radius: 0.5rem;

    --grid-row-size: minmax(0, 1fr);
    --grid-col-size: minmax(0, 1fr);

    --content: rgb(252, 252, 252);
    --content-foreground: theme("colors.gray.800");

    --explore-start: theme("colors.violet-red.500");
    --explore-end: theme("colors.yellow.400");

    --participate-start: theme("colors.cyan.400");
    --participate-end: theme("colors.blue.500");

    --promote-start: theme("colors.emerald.300");
    --promote-end: theme("colors.teal.400");
  }

  .dark {
    --background: 234.67deg 54.22% 16.27%;
    --foreground: 213 31% 91%;

    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;

    --popover: theme("colors.jacarta.600");
    --popover-foreground: theme("colors.gray.200");

    --card: theme("colors.jacarta.950");
    --card-foreground: 60 9.1% 97.8%;

    --border: theme("colors.jacarta.900");

    --input: theme("colors.jacarta.700");
    --input-disabled: theme("colors.jacarta.400");

    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;

    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;

    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;

    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;

    --ring: theme("colors.jacarta.900");

    --radius: 0.5rem;

    --content: theme("colors.blue.950");
    --content-foreground: theme("colors.gray.100");
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  @apply hidden h-0 w-0;
}

input[type="search"]::-ms-reveal {
  @apply hidden h-0 w-0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  @apply hidden;
}

/* Cursor pointer for file upload */
::-webkit-file-upload-button {
  @apply cursor-pointer;
}

/* Scrollbar */
.scrollbar-custom {
  scrollbar-width: thin;
}

.scrollbar-custom::-webkit-scrollbar {
  overflow: overlay;
  @apply h-2 w-2;
}

.scrollbar-custom::-webkit-scrollbar-track {
  @apply bg-stone-100 dark:bg-jacarta-800;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-jacarta-300 dark:bg-jacarta-600;
}

/* Dropdowns */
.dropdown--is-open + .js-dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu.show {
  @apply block;
}

.dropdown-toggle > i,
.dropdown-toggle > button {
  @apply transition-transform;
}

.dropdown-toggle.show > i,
.dropdown-toggle.show > button {
  @apply rotate-180;
}

.dropdown-menu-end {
  --bs-position: end;
}

/* Mobile Menu */
.nav-menu--is-open {
  @apply visible flex flex-col items-start overflow-y-auto px-6 pb-6 pt-0 opacity-100;
}

.nav-open-noscroll {
  @apply h-screen overflow-hidden;
}

/* Slider */
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  @apply text-[0] content-['prev'];
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  @apply text-[0] content-['next'];
}

.swiper-button-disabled {
  @apply hidden;
}

/* Lazyload Preloader */
.swiper-lazy-preloader {
  @apply absolute left-1/2 top-1/2 z-10 -ml-[21px] -mt-[21px] box-border h-[42px] w-[42px] origin-center animate-spin rounded-full border-4 border-accent border-t-transparent;
}

/* Full slider with thumbs */
.full-slider-thumbs .glider-slide-thumb-active {
  @apply bg-white/10;
}

.glider-slide-thumb-active .progress {
  @apply w-full transition-width duration-5000 ease-linear;
}

/* 3D shadow */
.swiper-3d .glider-slide-shadow-left,
.swiper-3d .glider-slide-shadow-right {
  @apply rounded-2.5xl;
}

/* Tooltips */
.tippy-box[data-theme~="xhibiter"] {
  @apply bg-jacarta-700;
}

.tippy-box[data-theme~="xhibiter"][data-placement^="top"]
  > .tippy-arrow::before {
  @apply border-t-jacarta-700;
}

.tippy-box[data-theme~="xhibiter"][data-placement^="bottom"]
  > .tippy-arrow::before {
  @apply border-b-jacarta-700;
}

.tippy-box[data-theme~="xhibiter"][data-placement^="left"]
  > .tippy-arrow::before {
  @apply border-l-jacarta-700;
}

.tippy-box[data-theme~="xhibiter"][data-placement^="right"]
  > .tippy-arrow::before {
  @apply border-r-jacarta-700;
}

/* Sticky Header */
.js-page-header--is-sticky {
  @apply bg-white/50;
}

.js-page-header--is-sticky.page-header--transparent,
.dark .js-page-header--is-sticky {
  @apply bg-jacarta-700/50;
}

/* Tabs */
.nav-link.active {
  @apply text-jacarta-700 after:absolute after:inset-x-0 after:-bottom-px after:block after:h-[2px] after:w-full after:bg-accent dark:text-white dark:hover:text-white;
}

.tab-content > .tab-pane {
  @apply hidden;
}

.tab-content > .active {
  @apply block;
}

/* Modals */
.modal {
  @apply fixed left-0 top-0 z-50 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-0;
}

.modal-dialog {
  @apply pointer-events-none relative mx-auto my-12 w-auto;
}

.modal-dialog-centered {
  @apply flex items-center;
}

.modal.fade .modal-dialog {
  @apply -translate-y-14 translate-x-0 transition-transform duration-300 will-change-transform;
}

.modal.show .modal-dialog {
  @apply transform-none;
}

.modal-content {
  @apply pointer-events-auto relative mx-4 flex flex-col rounded-2xl bg-white bg-clip-padding outline-0 dark:bg-jacarta-700;
}

.modal-header {
  @apply flex shrink-0 items-center justify-between border-b border-stone-100 p-6 dark:border-jacarta-600;
}

.modal-title {
  @apply mb-0 mt-1 font-display text-xl font-semibold leading-normal text-jacarta-700 dark:text-white;
}

.btn-close {
  @apply absolute right-6 border-0 bg-transparent transition-transform hover:rotate-180;
}

.modal-body {
  @apply relative flex-auto;
}

.modal-footer {
  @apply flex shrink-0 flex-wrap items-center justify-center border-t border-stone-100 p-6 dark:border-jacarta-600;
}

.modal-backdrop {
  @apply fixed left-0 top-0 z-40 h-screen w-screen bg-jacarta-900;
}

.modal-backdrop.fade {
  @apply opacity-0;
}

.modal-backdrop.show {
  @apply opacity-75;
}

/* Lightbox Modal */
.lightbox .modal-dialog {
  @apply flex min-h-[calc(100%_-_6rem)] max-w-5xl;
}

.lightbox .modal-content {
  @apply w-full overflow-hidden;
}

.lightbox .ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio {
  @apply relative before:block before:pt-[var(--bs-aspect-ratio)];
}

.ratio > * {
  @apply absolute left-0 top-0 h-full w-full;
}

.lightbox .btn-close {
  @apply right-2 top-2 rounded-full !bg-jacarta-900/30;
}

.lightbox .btn-close svg {
  @apply !top-0 h-3 w-3;
}

/* Accordions */
.accordion-button {
  overflow-anchor: none;
}

.accordion-button:not(.collapsed) .accordion-arrow {
  @apply -rotate-180;
}

/* Transitions: Fade / Collapse */
.fade {
  @apply transition-opacity ease-linear;
}

.collapse:not(.show) {
  @apply hidden;
}

.collapsing {
  @apply h-0 overflow-hidden transition-height;
}

/* Likes */
.js-likes--active svg {
  @apply opacity-0;
}

.js-likes--active::before {
  @apply !opacity-100;
}

/* Article */
.article-content > *:not(.article-content-wide) {
  @apply mx-auto mt-6 max-w-[48.125rem];
}

.article-content > h1,
.article-content > h2,
.article-content > h3,
.article-content > h4,
.article-content > h5,
.article-content > h6 {
  @apply !mt-12 mb-4 font-display text-jacarta-700 dark:text-white;
}

.article-content > p {
  @apply dark:text-zinc-300;
}

/* Alerts */
.alert-error {
  @apply bg-red-500/10 text-red-500;
}

.alert-success {
  @apply bg-green-500/10 text-green-500;
}

/* Gradient animation */
.animate-gradient {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    270deg,
    rgb(255, 115, 86) 0%,
    rgb(255, 89, 226) 25.52%,
    rgb(82, 221, 246) 50%,
    rgb(234, 223, 78) 76.04%,
    rgb(255, 115, 86) 100%
  );
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
}

.transform-style-3d {
  transform-style: preserve-3d;
}

.dark body {
  background-color: black;
}

.ticket-mask {
  mask-image: url(/img/ticket-mask-horizontal.png);
  mask-repeat: no-repeat;
  mask-size: 100% auto;
  mask-position: center;

  -webkit-mask-image: url(/img/ticket-mask-horizontal.png);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% auto;
  -webkit-mask-position: center;
}

html,
body {
  margin: 0;
  height: 100%;
}

* {
  min-width: 0px;
}
#__next {
  height: 100%;
}

.ql-snow,
.ql-snow *,
.ql-picker,
.ql-stroke {
  border: none !important;
  color: inherit !important;
  stroke: currentColor !important;
  background-color: inherit !important;
}

.ql-tooltip {
  background-color: var(--input) !important;
}

.ql-picker {
  background-color: var(--input) !important;
}

.ql-action {
  margin-left: 1px;
}
